jQuery(document).ready(function($) {

// $('#myTab2').on('change', function (e) {
//   var tabId = $(this).val();
//   console.log(tabId)
//   $ ('.main-tab-pane > .tab-pane').removeClass("show active");
//     $(".main-tab-pane " + tabId ).addClass("show active");

//    });

 var owl_service_listing = $('.owl-service-listing');
//    owl_service_listing.on('initialize.owl.carousel initialized.owl.carousel', function(event) {
//     console.log("test2", event)
//     $(event.target).find('.owl-item.active').addClass('animated fadeIn');

// });

  var owl_service_listing = $('.owl-service-listing');
//    owl_service_listing.on('initialize.owl.carousel initialized.owl.carousel', function(event) {
//     console.log("test2", event)
//     $(event.target).find('.owl-item.active').addClass('animated fadeIn');

// });
 owl_service_listing.owlCarousel({
    loop: true,
    margin: 0,
    rewind: true,
    slideBy:3,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    dots: false,
            // autoHeight: true,
    lazyLoad: true,
    responsive: {
        0: {
            items: 1,
            autoWidth: true,
            lazyLoad: true,
        },
        320: {
            items: 1,
            margin: 20,
            loop: true,
            lazyLoad: true,
            autoplay: true,
            autoWidth: false,
            stagePadding: 50,
            autoplay: true,
            mergeFit: true,
            merge: false,
            autoHeight:true,
            autoplayHoverPause:true,
            slideTransition: 'linear',
            autoplayTimeout: 2000,
            autoplaySpeed: 2000,
            smartSpeed: 1000,
        },
        480: {
            items: 1,
            margin: 20,
            loop: true,
            lazyLoad:true,
            stagePadding: 50
        },
        768: {
            items: 2,
            margin: 15,
            loop: true,
            lazyLoad: true,
        },
        1000: {
            items: 3,
            dots: false,
            margin: 30,
            lazyLoad: true,
                    animateIn: 'fadeIn', // add this
                    animateOut: 'fadeOut',
                    stagePadding: 0,
                },
            },

            // onRefreshed: function(e) {
            //     e.preventDefault();
            //     teamheight();
            // },
        });
//    owl_service_listing.on('translated.owl.carousel', function(event) {
//     console.log("test3", event)
//     $(event.target).find('.owl-item').removeClass('animated fadeIn');
//     $(event.target).find('.owl-item.active').addClass('animated fadeIn');
// })
//    owl_service_listing.on('dragged.owl.carousel', function() {
//     var now_slide = $('.owl-service-listing').find('.owl-item.active .card-icon-section');
//     now_slide.toggleClass('animated fadeIn');
// });
 
var owl_service_listing_inner = $('.inner-carousel');


owl_service_listing_inner.owlCarousel({
    loop: true,
        margin: 0,
        rewind: true,
        autoplayTimeout: 5000,
        nav: false,
        dots: false,
        autoHeight: true,
        lazyLoad: true,
        items: 1,
        autoplay: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
            // autoHeight: true,
    //lazyLoad: true,

    responsive: {
        0: {
            items: 1,
            autoWidth: true,
            lazyLoad: true,
        },
      
        480: {
            items: 1,
            margin: 20,
            loop: true,
            lazyLoad:true,
            stagePadding: 50
        },
        768: {
            items: 1,
            margin: 15,
            loop: true,
            lazyLoad: true,
        },
        1000: {

             loop: true,
        margin: 10,
        rewind: true,
        autoplayTimeout: 5000,
        nav: false,
        dots: false,
        autoHeight: true,
        lazyLoad: true,
        items: 1,
        autoplay: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
 
},
}

            // onRefreshed: function(e) {
            //     e.preventDefault();
            //     teamheight();
            // },
});





$('#prev-service').click(function() {
  owl_service_listing_inner.trigger('prev.owl.carousel');
})
// Go to the previous item
$('#next-service').click(function() {
 owl_service_listing_inner.trigger('next.owl.carousel');

})


$('#owl-expertise-listing').owlCarousel({
    loop: true,
    margin: 0,
    rewind: true,
    nav: false,
    dots: false,
    autoHeight: false,
    lazyLoad: true,
    items: 5,
    autoplay: true,
    autoWidth: false,
    stagePadding: 0,
    autoplay: true,
    mergeFit: true,
    merge: false,
    autoHeight:true,
    autoplayHoverPause:true,
    slideTransition: 'linear',
    autoplayTimeout: 4000,
    autoplaySpeed: 4000,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
            stagePadding: 0,
        },
        320: {
            items: 1,
            margin: 20,
            stagePadding: 50,
            loop: true,
        },
        540: {
            items: 2,
            margin: 20,
            stagePadding: 50,
            loop: true,
            stagePadding: 50,
        },
        768: {
            items: 2,
            margin: 30,
            loop: true,
            stagePadding: 50,
        },
        1000: {
            items: 4,
            dots: false,
            margin: 30,
            nav: true,
            stagePadding: 0,
            navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>'],
        },
    }
});

var owlnew = $('.wonderland-gallery-1');


owlnew.owlCarousel({
   
    loop: true,
    margin: 0,
    rewind: true,
    nav: false,
    dots: false,
    autoHeight: false,
    lazyLoad: true,
    items: 6,
    autoplay: true,
      autoWidth: true,
    loop: true,
      stagePadding: 0,
    autoplay: true,
    // mergeFit: true,
    merge: false,
    //autoHeight:true,
    autoplayHoverPause:true,
    slideTransition: 'linear',
    autoplayTimeout: 4000,
    autoplaySpeed: 4000,
    smartSpeed: 2000,
    responsive: {
        0: {
            items: 1,
            stagePadding: 0,
        },
        320: {
            items: 1,
            margin: 20,
            stagePadding: 50,
            loop: true,
            nav: false,
        },
        540: {
            items: 2,
            margin: 20,
            stagePadding: 50,
            loop: true,
            stagePadding: 0,
            nav: false,
        },
        768: {
            items: 3,
            margin: 15,
            loop: true,
            stagePadding: 0,
            nav: false,
        },
        1000: {
            items: 5,
            dots: false,
            margin: 40,
            nav: false,
            stagePadding: 0,
            navText: ['<i class="bi bi-chevron-left"></i>', '<i class="bi bi-chevron-right"></i>'],
        },
    },
     onInitialized: function() {
      owlnew.trigger('refresh.owl.carousel');
    },
});

 Fancybox.bind('[data-fancybox="dialog-team"]', {
        // Custom options for all galleries
      }); 
$('.wonderland-mobile-gallery').owlCarousel({
 items: 1,
            margin: 20,
            loop: false,
            stagePadding: 70
});
  // Adjust heights of items
  function setOwlHeight() {
    var maxHeight = 0;
    $('.owl-item').height('auto').each(function() {
      var itemHeight = $(this).height();
      maxHeight = (itemHeight > maxHeight) ? itemHeight : maxHeight;
    });
    $('.owl-item').height(maxHeight);
  }

  // Call setOwlHeight function when the carousel changes
  owlnew.on('changed.owl.carousel', function(event) {
    setOwlHeight();
  });

  // Initialize the height on document ready
  setOwlHeight();
$("#attach-file").change(function() {
    var file_uplaod_msg = $(this).val().replace(/.*(\/|\\)/, '');
    $(".file-upload-msg").html("<span>" + file_uplaod_msg + "</span>")
});
if (document.querySelector( '.wpcf7' )) {
    var wpcf7Elm = document.querySelector( '.wpcf7' );

    wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {
      $(".file-upload-msg > span").remove()
  }, false );
}
$( "#myTab2" ).selectmenu({ 
    change: function( e, ui ) { 
     var tabId = $(this).val();
  console.log(tabId)
  $ ('.main-tab-pane > .tab-pane').removeClass("show active");
    $(".main-tab-pane " + tabId ).addClass("show active");
     }
}).addClass( "mobile-drp" );
  Fancybox.bind('[data-fancybox^="dialog-"]', {
        Image: {
            zoom: false,
        },
        Thumbs: false,
        Hash: false,
         on: {
          reveal: function (instance) {
            instance.width = 600; // Set the desired width here
          }
        },
        bgColor: '#002f6b99',

    });
  function setContainerWidth() {
    var windowWidth = $(window).width();
    var container = $(".container");
  var leftSpace = parseInt(container.css("margin-left"));
    var marginWidth = 20; // Adjust the margin width as needed
    var containerWidth = windowWidth - leftSpace;
        //console.log("Left-side space: " + containerWidth + "px")

    $(".custom-container").css("width", containerWidth).css("margin-left", + leftSpace + "px");
  }
  setContainerWidth();
  $(window).resize(function() {
    setContainerWidth();
  });
  var container = $(".container");
  var leftSpace = parseInt(container.css("margin-left"));

  //console.log("Left-side space: " + leftSpace + "px");
  $('[data-aos]').parent().addClass('hideOverflowOnMobile');

  Fancybox.bind('[data-fancybox="gallery-map"]', {
   groupAttr: false,
   Panzoom: {
                click: "toggleCover",
              Toolbar: {
                display: ["zoomIn", "zoomOut"],
              },

            }
});
   Fancybox.bind('[data-fancybox="gallery1"]', {
  compact: false,
        Toolbar: false
});
   Fancybox.bind('[data-fancybox="gallery2"]', {
  compact: false,
        Toolbar: false
});
   if($(window).width() >= 767) {
   Fancybox.bind('[data-fancybox="dialog-new"]', {
});
}
});


AOS.init({
        duration: 1000,
        once: true,
    });